import React from "react";
import useTheme from '@mui/material/styles/useTheme';
import Carousel from "react-multi-carousel";
// https://github.com/YIZHUANG/react-multi-carousel
// https://w3js.com/react-multi-carousel
import "react-multi-carousel/lib/styles.css";
import "./myReactMultiCarousel.css";


const MyReactMultiCarousel2 = ({
  multiCarouselOptions = {},
  children,
  mySlider = "MySlider"
}) => {

  const theme = useTheme()
  // const themeBreakpointsKeys = theme.breakpoints.keys
  const themeBreakpointsValues = Object.values(theme.breakpoints.values)
  const optionsForDeviceSize = {
    xl: {
      breakpoint: {
        max: 8000,
        min: themeBreakpointsValues[ 4 ] + 1,
      },
      items: 5,
    },
    lg: {
      breakpoint: {
        max: themeBreakpointsValues[ 4 ],
        min: themeBreakpointsValues[ 3 ] + 1,
      },
      items: 5,
    },
    md: {
      breakpoint: {
        max: themeBreakpointsValues[ 3 ],
        min: themeBreakpointsValues[ 2 ] + 1,
      },
      items: 5,
    },
    sm: {
      breakpoint: {
        max: themeBreakpointsValues[ 2 ],
        min: themeBreakpointsValues[ 1 ] + 1,
      },
      items: 3,
    },
    xs: {
      breakpoint: {
        max: themeBreakpointsValues[ 1 ],
        min: 0,
      },
      items: 1,
    },
  }

  const deviceType = "sm"

  const defaultMultiCarouselOptions = {
    additionalTransfrom: 0,
    arrows: true,
    autoPlay: false,
    autoPlaySpeed: 5000,
    centerMode: true,
    className: `${mySlider}`,
    containerClass: `${mySlider}-container`,
    dotListClass: `${mySlider}-dotList`,
    draggable: true,
    focusOnSelect: false,
    infinite: false,
    itemClass: `${mySlider}-item`,
    keyBoardControl: true,
    minimumTouchDrag: 80,
    renderButtonGroupOutside: false,
    renderDotsOutside: false,
    sliderClass: `${mySlider}-slider`,
    responsive: optionsForDeviceSize,
    showDots: false,
    slidesToSlide: 1,
    swipeable: true,
    deviceType: deviceType
  };
  const resultMultiCarouselOptions = {
    ...defaultMultiCarouselOptions,
    ...multiCarouselOptions,
  };

  return (
    <Carousel {...resultMultiCarouselOptions} ssr key={mySlider} >
      {children}
    </Carousel>
  );
};

export default MyReactMultiCarousel2;
